<template>
  <el-card>
    <div class="header">This is sidebar secetion</div>
  </el-card>
</template>

<script>
export default {
  name: "Promotion"
};
</script>
